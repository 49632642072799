.chatBody{
    padding: 0px 35px;
    overflow-y: scroll;
    height: calc(70vh - 180px);
    position: relative;
}

.chatBody::-webkit-scrollbar{
    width: 8px;
}
.chatBody::-webkit-scrollbar-track {
    background: none;
  }
.chatBody::-webkit-scrollbar-thumb {
    background: #EDEDED;
    border-radius: 8px;
  }
/* .chatBody::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */

.BottomRightMsgContainer{
    display: flex;
    align-items: flex-start;
    justify-content: start;
    max-width: 70%;
}

.BottomRightMsgContainer.myMsg{
    margin-left: auto;
}

/* .BottomRightMsgDetails > p{
    padding: 0;
    margin: 0;
} */

.BottomRightMsgDetails{
    margin-left: 12px;
}

.BottomRightMsgDetails.myMsg{
    display: flex;
    flex-direction: column;
    align-items: end;
}

.BottomRightMsg{
    padding: 10px 12px;
    background-color: #EDEDED;
    border-radius: 12px;
    margin: 0;
    color: #000B23;
    font-size: 12px;
    /* max-width: 70%; */
}
.BottomRightMsg.myMsg{
    color: #FFFFFF;
    background-color: #0099A8;
}

.BottomRightTime{
    padding: 0;
    margin-top: 2px;
    font-size: 13px;
    font-weight: 500;
    color: #0E7CEB;
}
.BottomRightTime.myMsg{
    color: #F78DA7;
}

.ProfileIconChatBody{
    min-width: 48px;
    min-height: 48px;
}

.ChatBodyFormContainer{
    /* position: sticky; */
    bottom: 0vh;
    margin: 0px 35px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    border: 2px solid #EDEDED;
    border-radius: 12px;
}

.ChatBodyFormContainerInput{
    border: none;
    padding-bottom: 24px;
    outline: none;
}
.ChatBodyFormContainerInput:focus{
    border: none !important;
    outline: none !important;
}

.ChatBodyFormContainerIconsContainer{
    display: flex;
    border-top: 2px solid #EDEDED;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    padding-top: 8px;
}

.ChatBodyFormContainerIcons{
    display: flex;
    gap: 10px;
    align-items: center;
}