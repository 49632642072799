


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Inter', sans-serif !important;
  
  }
  .ant-picker{
    width: 100%;
  }

  .removeAstrick .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    display: none;
  }
  .dashboardChartCust .apexcharts-legend.apexcharts-align-right.apx-legend-position-top{
    right: 100px !important;
    margin-right: 0px !important;
  }
  .ScrollY {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 8px 24px;
    height: 300px;
  }

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0099A8 !important;
}

.ant-tabs-ink-bar{
  background: #0099A8 !important;
}

.tabs_centered .ant-tabs-nav-list{
  margin-left: 25.55%;
}

.ant-input-number{
  width: 100%;
}

/* SearchBox Icon */
.ant-input-search-button {
  height: 2.5rem !important;
}

/* Primary Button */

.ant-btn-primary {
  color: #fff;
  background: #0099A8 !important;
  border-color: #0099A8 !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-primary:focus, .ant-btn-primary:hover {
  color: #fff;
  background: #01aab9 !important;
  border-color: #01aab9 !important;
}

/* Radio  */
.ant-form-item-control-input-content{
  justify-content: start !important;
}
 .ant-tabs-tab:hover,
 ant-tabs-tab-active:hover {
  color: #01aab9 !important;
  
}

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  border-radius: 0.625rem;
}

/* Sorter Columns Alignments */

/* .ant-table-column-sorters{
  padding-left: 0;
} */


/* loading spin blur */
.blur {
  filter: blur(3px);
}

/* form disabled styles */
.form-disabled , .form-disabled .ant-select-selector .ant-select-selection-item{
  color: #1a3353 !important;
}


/* Icon zoom in animations */

@keyframes zoom-in {
  form{
    transform: scale(1);
  }

  to{
    transform: scale(1.4);
  }
}

.zoom-in-icon svg {
  transform-origin: center;
}

.zoom-in-icon:hover svg {
  animation: zoom-in 0.2s ease-in-out forwards;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: inherit !important;
  left: 10px !important;
  z-index: 2 !important;
  color: black  !important;
  font-size: 40px !important;
  cursor: pointer !important;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: inherit !important;
  right: 10px !important;
  z-index: 2 !important;
  font-size: 40px !important;
  color: black !important;
  cursor: pointer !important;
}

iframe {
  display: none !important;
}

@page {
  size: auto;
  margin : auto auto 0 auto;
}